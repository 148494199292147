(function () {
  'use strict';

  angular
    .module('neo.home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/home',
        templateUrl: 'home/home.tpl.html',
        controller: 'HomeCtrl',
        controllerAs: 'vm',
        resolve: {
          userResolve: userResolveFunction
        }
      });

    function userResolveFunction($q, User, AclService) {
      var deferred = $q.defer();
      User.get().$promise
        .then(function (data) {
          AclService.setFeatureAcls(data.featureAcls);
          AclService.setRoles(data.roles);
          deferred.resolve(data);
        })
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }
  }
}());
